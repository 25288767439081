import React from 'react';
import PropTypes from 'prop-types';
import styles from './PFSServiceHeader.module.css';
import globalStyles from '../../styles/global.module.css';

const PFSServiceHeader = ({ title, subtitle }) => {
  return (
    <div className={styles.PFSServiceHeader} data-testid="PFSServiceHeader">
      <p className={[globalStyles.SubtitleText, globalStyles.Fade].join(' ')}>{subtitle}</p>
      <p className={globalStyles.TitleText}>{title}</p>
    </div>
  );
};

PFSServiceHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PFSServiceHeader.defaultProps = {
  title: 'PFS Global',
  subtitle: 'Focused on you',
};

export default PFSServiceHeader;
