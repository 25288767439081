import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './PFSMeetTheTeam.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import AccordionSection from '../AccordionSection/AccordionSection';
import PFSStaffMember from '../PFSStaffMember/PFSStaffMember';
import { AdminSvg } from '../PFSWhoWeAreContent/Svg';
import { getStaffData } from '../../lib/GetStaffData';

const PFSMeetTheTeam = ({ department }) => {
  const [staff, setStaff] = useState([]);
  const [showSection, setShowSection] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStaffData(department)
      .then((response) => {
        setStaff(response);
      })
      .catch((e) => {
        setStaff([]); // TODO: Replace this with error image
      })
      .finally(() => {
        setLoading(false);
      });
  }, [department]);

  return (
    <ContentContainer>
      <div className={styles.PFSMeetTheTeam} data-testid='PFSMeetTheTeam'>
        <AccordionSection
          loading={loading}
          isOpen={showSection}
          Icon={<AdminSvg fill={showSection ? '#fff' : '#1775a9'} />}
          title={'Meet The Team'}
          onToggle={() => {
            setShowSection(!showSection);
          }}
        >
          <div data-testid='PFSMeetTheTeamChildren'>
            <TeamChildren staff={staff} />
          </div>
        </AccordionSection>
      </div>
    </ContentContainer>
  );
};

PFSMeetTheTeam.propTypes = {
  department: PropTypes.string.isRequired,
};

PFSMeetTheTeam.defaultProps = {
  department: undefined,
};

export default PFSMeetTheTeam;

export const TeamChildren = ({ staff }) => {
  return staff.map((staffMember, index) => (
    <PFSStaffMember
      key={index}
      name={staffMember.name}
      title={staffMember.title}
      image={staffMember.imageURL}
    />
  ));
};
