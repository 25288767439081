import React, { useEffect, useState, useCallback, useContext } from 'react';
import styles from './PFSWhoWeAreContent.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import AccordionSection from '../AccordionSection/AccordionSection';
import PFSStaffMember from '../PFSStaffMember/PFSStaffMember';
import { sections } from './Data';
import WhoWeAreContext from '../../contexts/WhoWeAreContext';

const PFSWhoWeAreContent = () => {
  const {
    loading,
    staffData,
    currSection,
    setCurrSection,
    staffNameToId
  } = useContext(WhoWeAreContext);

  const [data, setData] = useState({});
  const groupBy = useCallback((arr, keyFn) => {
    let groupedData = {};

    for (let i = 0; i < arr.length; i++) {
      let thisElem = arr[i];
      let thisKey = keyFn(thisElem);

      if (groupedData[thisKey] === undefined) {
        groupedData[thisKey] = [];
      }

      groupedData[thisKey].push(thisElem);
    }

    return groupedData;
  }, []);

  useEffect(() => {
    if (staffData) {
      let groupedData = groupBy(staffData, (val) => {
        return val.department;
      });
      setData(groupedData);
    }
  }, [staffData, groupBy]);
  
  return (
    <ContentContainer>
      <div
        className={styles.PFSWhoWeAreContent}
        data-testid='PFSWhoWeAreContent'
      >
        {sections?.map((section, index) => (
          <AccordionSection
            loading={loading}
            autoScroll={true}
            key={index}
            sectionId={section.department}
            Icon={
              section.Icon && (
                <section.Icon
                  fill={currSection === section.department ? '#fff' : '#1775a9'}
                />
              )
            }
            isOpen={currSection === section.department}
            title={section.title}
            onToggle={() =>
              setCurrSection(currSection === section.department ? '' : section.department)
            }
          >
            <div data-testid='PFSWhoWeAreContentChildren'>
              {data[section.department]?.map((staffMember, index) => {
                return (
                  <PFSStaffMember
                    id={staffNameToId(staffMember.name)}
                    key={index}
                    name={staffMember.name}
                    title={staffMember.title}
                    image={staffMember.imageURL}
                  />
                );
              })}
            </div>
          </AccordionSection>
        ))}
      </div>
    </ContentContainer>
  );
};

export default PFSWhoWeAreContent;
