import React from 'react';
import styles from './PFSHuang.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import { NavLink, useLocation } from 'react-router-dom';

import HuangImage from './huang.png';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { AcquisitionsIndexBreadcrumb } from '../PFSAcquisitionsIndex/PFSAcquisitionsIndex';

const PFSHuang = () => {
  const { pathname } = useLocation();
  useBreadcrumb([
    AcquisitionsIndexBreadcrumb,
    {
      label: 'Huang',
      url: pathname,
    },
  ]);

  return (
    <div className={styles.PfsHuang} data-testid='pfsHuang'>
      <ContentContainer>
        <img className={styles.logo} alt='Huang CPA, PLLC' src={HuangImage} />
        <div className={styles.content}>
          <h1>Huang CPA, PLLC has merged with PFS Global!</h1>
          <p>Dear Valued Clients and Friends:</p>
          <p>
            I am writing to inform you of an important and exciting development
            regarding my practice. After 15 years of serving the community as an
            independent CPA, I have made the decision to transition my practice
            to PFS Global, a trusted financial management firm led by Aaron
            Stocks. This change took effect on November 8, 2024.
          </p>
          <p>
            PFS Global has a strong reputation for providing exceptional
            service, and I am confident that this transition will provide even
            greater value to you. In addition to the services you are accustomed
            to, such as bookkeeping, taxes, and payroll, PFS Global offers
            expanded expertise in areas such as HR, legal services, and business
            advisory.
          </p>
          <p>
            Please note that the office location will remain the same at 8430
            University Executive Park Drive, Ste 613, Charlotte, NC 28262.
          </p>
          <p>
            I will continue to be available for the first year following this
            transition to ensure a smooth process and assist with any questions
            or concerns that may arise. During this time, I will work closely
            with the PFS Global team to ensure the same level of personalized
            attention and care that you have come to expect.
          </p>
          <p>
            As part of this change, your client files, including tax returns,
            accounting files, and workpapers, will be securely transferred to
            PFS Global. Should you prefer to have your files returned to you,
            please contact me before February 6, 2025, at
            <a href='mailto:kalin@huangcpapllc.com'>
              {' '}
              kalin@huangcpapllc.com
            </a>{' '}
            or 704-831-6270.
          </p>
          <p>
            I want to extend my heartfelt thanks for the trust and loyalty
            you’ve shown me over the years. I am confident that this transition
            will allow us to continue serving you with the same dedication and
            care. If you have any questions or concerns, please don’t hesitate
            to reach out. Your satisfaction remains our top priority, and I am
            here to ensure this process is seamless for you.
          </p>

          <div>Sincerely,</div>
          <div>Ko-Chen (Kalin) Huang, CPA</div>
          <NavLink to={'/'} id={styles.homeBtn}>
            Continue
          </NavLink>
        </div>
      </ContentContainer>
    </div>
  );
};

export default PFSHuang;
