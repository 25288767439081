import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import styles from './AccordionSection.module.css';

const AccordionSection = (props) => {
  const { autoScroll, isOpen, title, onToggle, Icon, loading, sectionId } = props;
  let classNames = [styles.SupportBar];
  classNames[1] = isOpen ? `${styles.Open} ${styles.WhiteFont}` : styles.Closed;

  const accordion = useRef();

  const handleClick = useCallback(() => {
    onToggle();
    if (isOpen) return;
    if (!autoScroll) return;
    setTimeout(() => {
      window.scrollTo({
        top: accordion.current?.offsetTop - 25,
        behavior: 'smooth',
      });
    }, 0);
  }, [onToggle, isOpen, autoScroll]);

  return (
    <>
      <div
        ref={accordion}
        className={styles.AccordionSection}
        data-testid='AccordionSection'
        id={sectionId}
      >
        <div
          data-testid='Accordion'
          onClick={handleClick}
          className={classNames.join(' ')}
        >
          <div>
            <span className={styles.image} data-testid='AccordionSection-image'>
              {Icon}
            </span>
            <span data-testid='AccordionTitle'>{title}</span>
          </div>

          <FontAwesomeIcon
            data-testid='AccordionSection-icon'
            className={styles.icon}
            icon={isOpen ? faAngleUp : faAngleDown}
            size='2x'
          />
        </div>
        <center>
          {isOpen &&
            (loading ? (
              <div
                data-testid='AccordionSection-loader'
                className={styles.Loader}
              ></div>
            ) : (
              props.children
            ))}
        </center>
      </div>
    </>
  );
};
AccordionSection.propTypes = {
  Icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  autoScroll: PropTypes.bool,
  sectionId: PropTypes.string,
};
AccordionSection.defaultProps = {
  Icon: undefined,
  title: undefined,
  onToggle: undefined,
  loading: false,
  isOpen: undefined,
  autoScroll: false,
  sectionId: ''
};

export default AccordionSection;
