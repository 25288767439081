import React from 'react';
import styles from './PFSArchway.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import archwayLogo from './Archway_logo.png';
import { NavLink, useLocation } from 'react-router-dom';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { PartnersIndexBreadcrumb } from '../PFSPartnersIndex/PFSPartnersIndex';

const PFSArchway = () => {
  const { pathname } = useLocation();

  useBreadcrumb([
    PartnersIndexBreadcrumb,
    {
      label: 'Archway',
      url: pathname,
    },
  ]);

  return (
    <div className={styles.PfsArchway} data-testid='PfsArchway'>
      <ContentContainer>
        <img className={styles.logo} src={archwayLogo} alt='Archway' />
        <h1>Archway Computer, Inc. Joins PFS Global as a Partner!</h1>
        <div className={styles.content}>
          <p>
            We are pleased to announce that Archway Computer, Inc. has become a
            partner of PFS Global. Archway Computer, Inc. was established in
            2009 and is based in Agoura Hills, CA.
          </p>
          <p>
            Archway Computer is a trusted IT provider serving independent
            insurance agencies across North America. With over 30 years of
            specialized industry experience, Archway supports hundreds of
            agencies with comprehensive technology solutions. Our services
            include 24/7 help desk support, advanced cybersecurity and
            compliance, and strategic IT consulting - all tailored to meet the
            unique needs of insurance professionals. Archway’s deep knowledge of
            the insurance sector ensures seamless, secure operations, allowing
            agencies to thrive and focus on what matters most: their clients.
          </p>
          <p>
            This collaboration allows us to integrate Archway's advanced
            technology and expertise into our offerings, ensuring that we
            deliver enhanced value and efficiency to you, our esteemed clients.
          </p>
          <p>
            For more information about Archway, please visit{' '}
            <a href='http://www.myarchway.com'>myarchway.com</a>, or reach out
            directly at 888-361-9995 or{' '}
            <a href='mailto:info@myarchway.com'>info@myarchway.com</a>.
          </p>
          <p>
            We are excited about the opportunities this partnership presents and
            are available to address any inquiries or feedback you may have.
          </p>

          <NavLink to={'/'} id={styles.homeBtn}>
            Continue
          </NavLink>
        </div>
      </ContentContainer>
    </div>
  );
};

export default PFSArchway;
