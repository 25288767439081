import React from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import PFSServiceHeader from '../PFSServiceHeader/PFSServiceHeader';
import PFSServicePoints from '../PFSServicePoints/PFSServicePoints';
import PFSWhyChoosePFS from '../PFSWhyChoosePFS/PFSWhyChoosePFS';
import styles from './PFSWhatWeDoHCM.module.css';
import globalStyles from '../../styles/global.module.css';
import PFSMeetTheTeam from '../PFSMeetTheTeam/PFSMeetTheTeam';

import hcmImage from './hcm.jpg';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { WhatWeDoBreadcrumb } from '../PFSWhatWeDoIndex/PFSWhatWeDoIndex';
import { useLocation } from 'react-router-dom';

const PFSWhatWeDoHCM = () => { 
  const { pathname } = useLocation();

  useBreadcrumb([
    WhatWeDoBreadcrumb,
    {
      label: 'HCM',
      url: pathname,
    },
  ]);  
  return(
  <div className={styles.PFSWhatWeDoHCM} data-testid='PFSWhatWeDoHCM'>
    <ContentContainer>
      <PFSServiceHeader subtitle='Human Capital Management' title='HR for the Modern World.' />
      <div className={globalStyles.IntroContainer}>
        <img src={hcmImage} alt='Many hands joined together' className={globalStyles.IntroImage} />
        <div className={globalStyles.IntroText}>
          <p>PFS Global offers comprehensive HR and recruiting services to simplify your HR management and recruitment processes. We have expert knowledge of employment law and HR best practices, allowing us to navigate complex challenges with ease.</p>
          <p>Contact us today to learn more about our HR services and how they can help streamline your processes. With our expertise, you can take your business to the next level.</p>
        </div>
      </div>
      <PFSServicePoints
        title='Outsourced HR Manager'
        points={[
          'Employee Onboarding: new hire documents',
          'Employee Maintenance: maintenance documents, dispute resolution, benefits, management, reviews, trainings, file management',
          'Employee Offboarding: exit interviews, termination packages, separation documents',
        ]}
      />
      <PFSServicePoints
        title='Outsourced Recruiting Manager'
        points={[
          'Review job descriptions',
          'Review compensation analysis',
          'Review of exempt vs. non-exempt classifications',
          'Review of non-discretionary bonuses credited towards overtime',
          'Review California AB5 classifications (ABC Test) for independent contractor classifications',
          'Review qualification for statutory employee classifications',
          'Post job ads',
          'Review and send top resumes to company management',
          'Schedule interviews',
          'Reference checks',
          'Offer letters',
          'Background checks',
          'Schedule first date of employment',
        ]}
      />
    </ContentContainer>
    <PFSWhyChoosePFS />
    <PFSMeetTheTeam department='HCM' />
    <br />
    <br />
    <br />
    <br />
    <br />
    <PFSContactCta ctaText='Call us today!' />
  </div>
)};

PFSWhatWeDoHCM.propTypes = {};

PFSWhatWeDoHCM.defaultProps = {};

export default PFSWhatWeDoHCM;
