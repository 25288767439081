import React from 'react';
import PropTypes from 'prop-types';
import styles from './PFSStaffMember.module.css';
import placeholderImage from './empty.png';
import LoadingImage from '../LoadingImage/LoadingImage';

const PFSStaffMember = (props) => {
  return (
    <div
      id={props.id ?? ''}
      className={styles.PFSStaffMember}
      data-testid='PFSStaffMember'
    >
      <center>
        <div className={styles.ImageContainer}>
          <LoadingImage
            placeholderImage={placeholderImage}
            image={props.image}
            styleclass={styles.PFSStaffMemberImage}
            altText='Staff Member'
          />
        </div>
        <p data-testid='PFSStaffMemberName'>{props.name}</p>
        <p data-testid='PFSStaffMemberTitle' className={styles.PersonTitle}>
          {props.title}
        </p>
      </center>
    </div>
  );
};

PFSStaffMember.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  id: PropTypes.string,
};

PFSStaffMember.defaultProps = {
  name: undefined,
  title: undefined,
  image: undefined,
  id: undefined,
};

export default PFSStaffMember;
