import React from 'react';
import styles from './PFSDiamondBarMap.module.css';
import PFSTabs from '../PFSTabs/PFSTabs';

function generateUrlParams(object) {
  return Object.keys(object).map((thisKey) => {
    return `${thisKey}=${object[thisKey]}`;
  }).join('&');
}

const PFSDiamondBarMap = () => {
  const diamondBarQuery = 'place_id:ChIJRzgR4Fgow4AR2ttip8APm18';
  const lasVegasQuery = 'PFS Global, 1140 N Town Center Dr #320, Las Vegas, NV 89144, United States';
  const charlotteQuery = 'PFS Global, 8430 University Executive Park Dr #613, Charlotte, NC 28262';

  const defaultParams = {
    key: process.env.REACT_APP_GMAPS_KEY,
    zoom: 13,
  };
  
  const diamondBarParams = {
    ...defaultParams,
    q: diamondBarQuery,
  }

  const lasVegasParams = {
    ...defaultParams,
    q: lasVegasQuery,
  }

  const charlotteParams = {
    ...defaultParams,
    q: charlotteQuery,
  }

  const diamondBarIframeUrl = `https://www.google.com/maps/embed/v1/place?${generateUrlParams(diamondBarParams)}`;
  const lasVegasIframeUrl = `https://www.google.com/maps/embed/v1/place?${generateUrlParams(lasVegasParams)}`;
  const charlotteIframeUrl = `https://www.google.com/maps/embed/v1/place?${generateUrlParams(charlotteParams)}`;

  return (
    <div data-testid={"PFSDiamondBarMap"}>
      <PFSTabs
        items={[
          {
            label: 'California Office',
            key: '1',
            children: (
              <div className={styles.PFSDiamondBarMap}>
                <div id={styles.TitleContainer}>
                  <p id={styles.OfficeTitle}>California Office</p>
                  <p id={styles.StreetAddress}>556 N. Diamond Bar Blvd, #101 - Diamond Bar, CA 91765</p>
                </div>
                <div id={styles.MapContainer}>
                  <iframe
                    title={'California Office Map'}
                    style={{border:0}}
                    loading={"lazy"}
                    src={diamondBarIframeUrl}
                    data-testid='google-maps-iframe'
                  >
                  </iframe>
                </div>
              </div>
            )
          },
          {
            label: 'Nevada Office',
            key: '2',
            children: (
              <div className={styles.PFSDiamondBarMap}>
                <div id={styles.TitleContainer}>
                  <p id={styles.OfficeTitle}>Nevada Office</p>
                  <p id={styles.StreetAddress}>1140 N. Town Center Dr, #320 - Las Vegas, NV 89144</p>
                </div>
                <div id={styles.MapContainer}>
                  <iframe
                    title={'Nevada Office Map'}
                    style={{border:0}}
                    loading={"lazy"}
                    src={lasVegasIframeUrl}
                    data-testid='google-maps-iframe'
                  >
                  </iframe>
                </div>
              </div>
            )
          },
          {
            label: 'North Carolina Office',
            key: '3',
            children: (
              <div className={styles.PFSDiamondBarMap}>
                <div id={styles.TitleContainer}>
                  <p id={styles.OfficeTitle}>North Carolina Office</p>
                  <p id={styles.StreetAddress}>8430 University Executive Park Dr, #613 - Charlotte, NC 28262</p>
                </div>
                <div id={styles.MapContainer}>
                  <iframe
                    title={'North Carolina Office Map'}
                    style={{border:0}}
                    loading={"lazy"}
                    src={charlotteIframeUrl}
                    data-testid='google-maps-iframe'
                  >
                  </iframe>
                </div>
              </div>
            )
          }
        ]}
      />
    </div>
  )
};

PFSDiamondBarMap.propTypes = {};

PFSDiamondBarMap.defaultProps = {};

export default PFSDiamondBarMap;
