import React from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import PFSServiceHeader from '../PFSServiceHeader/PFSServiceHeader';
import PFSServicePoints from '../PFSServicePoints/PFSServicePoints';
import PFSWhyChoosePFS from '../PFSWhyChoosePFS/PFSWhyChoosePFS';
import styles from './PFSWhatWeDoLegal.module.css';
import globalStyles from '../../styles/global.module.css';
import PFSMeetTheTeam from '../PFSMeetTheTeam/PFSMeetTheTeam';

import legalImage from './legal.jpg'
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { WhatWeDoBreadcrumb } from '../PFSWhatWeDoIndex/PFSWhatWeDoIndex';
import { useLocation } from 'react-router-dom';
 
const PFSWhatWeDoLegal = () => {
  const { pathname } = useLocation();

  useBreadcrumb([
    WhatWeDoBreadcrumb,
    {
      label: 'Law',
      url: pathname,
    },
  ]);  
  return(
  <div className={styles.PFSWhatWeDoLegal} data-testid='PFSWhatWeDoLegal'>
    <ContentContainer>
      <PFSServiceHeader subtitle='PFS Law, APC' title='Law for the Modern World.' />
      <div className={globalStyles.IntroContainer}>
        <img src={legalImage} alt='' className={globalStyles.IntroImage} />
        <div className={globalStyles.IntroText}>
          <p>At PFS Law, APC, we offer comprehensive legal services for business owners and individuals. We take a client-centric approach to deliver customized solutions that meet your requirements.</p>
          <p>Our commitment to excellence, and our dedication to your success ensures that we achieve our legal objectives, providing peace of mind. Contact us today to learn how we can assist you.</p>
        </div>
      </div>
      <PFSServicePoints
        title='Business Transactions'
        points={[
          'Mergers and Acquisitions (Buying or Selling a Business)',
          'Document Drafting: Bylaws, Operating Agreement, or Partnership Agreement',
          'Entity Formations',
        ]}
      />
      <PFSServicePoints
        title='Business Owner Compliance'
        points={[
          'Agent for Service of Process in each state',
          'Annual Secretary of State Filings in each state',
          {
            label: 'Annual meeting with attorney to review and update:',
            subpoints: ["Meeting Minutes", "Bylaws, Operating Agreement, or Partnership Agreement", "Stock Certificates"]
          },
          'Annual Beneficial Ownership Information Reporting'
        ]}
      />
      <PFSServicePoints
        title='State and Local Tax (SALT)'
        points={[
          'Sales and Use Tax',
          'Secretary of State Filings',
          'County Business Property Statements',
          'City Business License Filings',
          'Affordable Care Act - 1095/1094 Processing',
        ]}
      />
      <PFSServicePoints
        title='Trust and Estate'
        points={[
          'Estate Planning',
          'Trust Reviews',
          'Trust Drafting',
          'Will Drafting',
        ]}
      />
      <PFSServicePoints
        title='Tax Resolution'
        points={[
          'Federal, State, and Local Tax Resolution',
          'Discovery',
          'Payment Plan Arrangements',
          'Current Non-Collectible',
          'Offer in Compromise',
        ]}
      />
    </ContentContainer>
    <PFSWhyChoosePFS />
    <PFSMeetTheTeam department='Legal' />
    <br />
    <br />
    <br />
    <br />
    <br />
    <PFSContactCta ctaText='Call us today!' />
  </div>
)};

PFSWhatWeDoLegal.propTypes = {};

PFSWhatWeDoLegal.defaultProps = {};

export default PFSWhatWeDoLegal;
