import React from 'react';
import styles from './PFSCareers.module.css';
import PFSCareersForm from '../PFSCareersForm/PFSCareersForm';
import ContentContainer from '../ContentContainer/ContentContainer';
import PfsCareersCoreValues from '../PFSCareersCoreValues/PFSCareersCoreValues';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { useLocation } from 'react-router-dom';

const PFSCareers = () => {
  const { pathname } = useLocation();

  useBreadcrumb([{
    label: 'Careers',
    url: pathname
  }])

  return (
    <div className={styles.PfsCareers} data-testid='PfsCareers'>
      <ContentContainer>
        <div id={styles.CareersContainer}>
          <div id={styles.CareersInfoContainer}>
            <h1 id={styles.HeaderText}>
              Start Your Career
              <br></br>
              <span id={styles.BoldHeader}>at PFS Global Today!</span>
            </h1>
            <p id={styles.BannerText}>
              At PFS Global, we pride ourselves on recruiting and retaining the most talented
              team members in our industries.
            </p>
            <p id={styles.BannerText}>
              Our friendly environment and distinguished training programs allow individuals to
              expand their careers. We're not just in the services business - we're also in the
              business of developing people.
            </p>
          </div>
          <div id={styles.CareersFormContainer}>
            <PFSCareersForm />
          </div>
          <br /><br />
          <PfsCareersCoreValues />
        </div>
      </ContentContainer>
    </div>
  )
};

PFSCareers.propTypes = {};

PFSCareers.defaultProps = {};

export default PFSCareers;
