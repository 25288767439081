import React from 'react';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSContactCta from '../PFSContactCta/PFSContactCta';
import PFSServiceHeader from '../PFSServiceHeader/PFSServiceHeader';
import PFSServicePoints from '../PFSServicePoints/PFSServicePoints';
import PFSWhyChoosePFS from '../PFSWhyChoosePFS/PFSWhyChoosePFS';
import PFSMeetTheTeam from '../PFSMeetTheTeam/PFSMeetTheTeam';

import { useBreadcrumb } from '../../hooks/useBreadcrumb';
import { WhatWeDoBreadcrumb } from '../PFSWhatWeDoIndex/PFSWhatWeDoIndex';
import { useLocation } from 'react-router-dom';

const PFSWhatWeDoAdvisors = () => {
  const { pathname } = useLocation();

  useBreadcrumb([
    WhatWeDoBreadcrumb,
    {
      label: 'Advisors',
      url: pathname,
    },
  ]);
  return (
    <div data-testid="PFSWhatWeDoAdvisors">
      <ContentContainer>
        <PFSServiceHeader
          subtitle="Advisory"
          title="Advisors for the Modern World."
        />

        <PFSServicePoints
          title='Chief Financial Officer'
          points={[
            'Financial Reporting Compliance',
            'Financial Statement Analysis',
            'Cost Reductions Analysis',
            'KPI Dashboard',
            'Financial Strategy Meetings',
            'Budget Development',
            'Financial Planning and Forecasting: for growth, for exit, for succession, for recession',
            'Cash Flow Forecasting',
            'Risk Management',
            'Contract Negotiations',
          ]}
        />

        <PFSServicePoints
          title='Assurance Readiness: Audits, Reviews, Compilations'
          points={[
            'Supporting Reconciliations and Schedules',
            'Accounting Policies',
            'Internal Controls',
            'Documentation Requirements',
            'Recommended Practices',
            'Organizing and Presenting Financial Data',
          ]}
        />
      </ContentContainer>
      <PFSWhyChoosePFS />
      <PFSMeetTheTeam department="Advisor" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <PFSContactCta ctaText="Call us today!" />
    </div>
  );
};

export default PFSWhatWeDoAdvisors;
